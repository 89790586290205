import PropTypes from 'prop-types';
import { omit } from '../../lib/nodash';

import usePageBodyComponents from '../DynamicPage/usePageBodyComponents';

const DynamicProductSpecsContent = ({ specsContent, ...rest }) => {
  const content = usePageBodyComponents(specsContent, omit(['blok'], rest));

  return content;
};

DynamicProductSpecsContent.propTypes = {
  product: PropTypes.object,
  specsContent: PropTypes.object,
};

export default DynamicProductSpecsContent;
